import { https } from '../utils/request.js';
//配置
export const Settings = data => https("get", `/api/user/settings`, data)
//登录
export const Login = data => https("post", `/api/user/login`, data)
//注册
export const Registe = data => https("post", `/api/user/registe`, data)
//获取用户信息
export const UserInfo = data => https("post", `/api/user/user_info`, data)
//佣金转余额
export const CommissionToProfit = data => https("post", `/api/profit/commission_to_profit`, data)
//更新密码
export const UpdatePassword = data => https("post", `/api/user/update_password`, data)
//获取用户工具
export const GetUserTools = data => https("post", `/api/user/get_user_tools`, data)
//提现
export const Withdraw = data => https("post", `/api/profit/withdraw`, data)
//获取邀请人数
export const GetInviteNum = data => https("post", `/api/user/get_invite_num`, data)
//获取邀请的用户
export const GetInviteUsers = data => https("get", `/api/user/invite_users`, data)
// 获取佣金记录
export const CommissionRecord = data => https("get", `/api/profit/commission_record`, data)
//获取订单
export const RechargePriceOrders = data => https("get", `/api/profit/get_recharge_price_orders`, data)
//获取所有工具
export const Tools = data => https("post", `/api/user/tools`, data)
//开通工具
export const OpenToolVip = data => https("post", `/api/user/open_tool_vip`, data)
//订单充值
export const RechargePrice = data => https("post", `/api/profit/recharge_price`, data)
//充值方式
export const Payments = data => https("get", `/api/profit/payments`, data)
//提现记录
export const OutCashRecords = data => https("get", `/api/profit/outcash_records`, data)
//教程和下载使用
export const ToolTuTorial = data => https("get", `/api/twitter/get_tool_tutorial`, data)

export const CancelTg = data => https("post", `/api/user/cancel_tg`, data)

